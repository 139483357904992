<template>
<div class="container-fluid">
    <div class="row">
        <sidebar></sidebar>
        <!-- MAIN CONTENT start -->
        <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
            <div class="row">
                <div class="col-12 mb-5">
                    <app-header></app-header>
                    <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                </div>
            </div>
            <!-- Payments section -->
            <section class="paymnets-section  px-0 mx-0 px-xl-5 mx-xl-5 mt-lg-2 mb-3">
                <div class="container-fluid  h-100">
                    <div class="row bg-light b-30 mt-5 h-100">
                        <div class="col-12">
                          <div class="card-body px-2 py-3">
                            <table v-if="loading" class="table table-blue">
                              <thead>
                              <tr>
                                <td></td>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td>
                                  <sk-list :height="0.04" :items-count="6"></sk-list>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <table v-else class="table table-blue">
                              <thead>
                              <tr>
                                <th>{{ $t('master-themes.theme-title') }}</th>
                                <th>{{ $t('master-themes.theme-description') }}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="theme of themes">
                                <td>{{ theme.theme }}</td>
                                <td>{{ theme.theme_description }}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    </div>
</div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import FinancialInfoResource from '../api/financialInfoResource';
import SkList from '../components/skeletons/sk-list';
import axios from 'axios';

const financialInfoResource = new FinancialInfoResource();

export default {
    name:'master-themes',
    components: { SkList, sidebar, appHeader, pageHeader },
    computed: {
      pageData() {
        return {
          title: this.$t('master-themes.title'),
            breadcrumb: [
              {
                icon: '',
                title: this.$t('home.title'),
                link: '/',
                isActive: false,
              },
              {
                icon: '',
                title: this.$t('master-themes.title'),
                link: '/master-themes',
                isActive: true,
              }
          ],
        };
      },
    },

    data() {
      return {
        themes: [],
        loading: false,
      }
    },

    mounted() {
        this.loadData();
    },

  methods: {
      loadData() {
        this.loading = true
        axios.get('/api/master-themes')
        .then(response => {
          this.themes = response.data.data
        })
        .finally(() => {
          this.loading = false
        })
      }
  }
}
</script>
